/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Skin
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-player[data-view-type='audio']) {
  --media-focus-ring: var(--audio-focus-ring, 0 0 0 3px rgb(78 156 246));
  border-radius: var(--audio-border-radius, 6px);
}

:where(media-player[data-view-type='audio'][data-focus] media-outlet) {
  box-shadow: unset;
}

:where(
    media-player[data-focus]:not([data-playing])
      media-community-skin[data-audio]
      div[part~='media-ui']
  ) {
  box-shadow: var(--media-focus-ring);
}

:where(media-community-skin[data-audio]) {
  --brand: var(--audio-brand, #f5f5f5);
  --media-font-family: var(--audio-font-family, sans-serif);
  --media-slider-track-fill-bg: var(--brand);
  --media-chapters-progress-bg: var(--brand);
  --media-menu-radio-check-active-color: var(--brand);
  --media-controls-color: var(--audio-controls-color, #f5f5f5);
  --media-tooltip-y-offset: 48px;
  --media-menu-x-offset: -4px;
  --media-menu-y-offset: 40px;
  --media-button-size: 34px;
  --media-slider-value-border: var(--audio-slider-value-border, 1px solid rgb(255 255 255 / 0.1));
}

:where(media-community-skin[data-audio][data-mobile]) {
  --media-tooltip-y-offset: 18px;
}

:where(media-community-skin[data-audio] div[part~='media-ui']) {
  position: relative;
  min-width: 100%;
  min-height: 80px;
  color: var(--audio-controls-color, #f5f5f5);
  background-color: var(--audio-bg, black);
  border: var(--audio-border, 1px solid rgb(255 255 255 / 0.1));
  border-radius: var(--audio-border-radius, 6px);
}

:where(media-community-skin[data-audio] div[part='controls']) {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Info
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] [part='info']) {
  display: flex;
  align-items: center;
}

:where(media-community-skin[data-audio] [part='main-title']) {
  display: inline-block;
  font-size: var(--audio-title-font-size, 14px);
  font-weight: var(--audio-title-font-weight, 500);
  font-family: var(--audio-font-family, sans-serif);
  color: var(--audio-title-color, rgba(255 255 255 / 0.64));
  flex: 1 1 0%;
  padding-inline: 8px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

:where(media-community-skin[data-audio]) [part='main-title']::before {
  content: '•';
  display: inline-block;
  margin-right: 6px;
  color: var(--audio-title-color, rgba(255 255 255 / 0.64));
}

:where(media-community-skin[data-audio]) [part='main-title']:empty::before {
  content: '';
  margin-left: 0;
}

:where(media-community-skin[data-audio][data-mobile] [part='main-title']) {
  padding-left: 0;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Time Group
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] div[part='time-group']) {
  display: flex;
  align-items: center;
  margin-left: 8px;
}

:where(media-community-skin[data-audio] [part='time-divider']) {
  margin: 0 2px;
  color: var(--audio-time-divider-color, rgb(224, 224, 224));
}

:where(media-community-skin[data-audio][data-mobile] media-time) {
  padding: 0 6px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Controls
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] div[part='controls']) {
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  max-width: 100%;
  padding-inline: 8px;
}

:where(media-community-skin[data-audio] div[part='controls-group']) {
  display: flex;
  align-items: center;
  pointer-events: auto;
  width: 100%;
}

:where(media-community-skin[data-audio] div[part='controls-group']:first-child) {
  flex: 1 1 0%;
  min-width: 0;
}

:where(media-community-skin[data-audio] div[part='controls-group']:nth-child(2)) {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -16px;
}

:where(media-community-skin[data-audio] div[part='controls-group']:last-child) {
  margin-bottom: 4px;
  z-index: 99;
}

:where(media-community-skin[data-audio] [data-media-button]) {
  min-width: var(--audio-button-size, 40px);
  min-height: var(--audio-button-size, 40px);
  margin-right: 4px;
}

:where(media-community-skin[data-audio] media-play-button) {
  width: var(--audio-play-button-size, 30px);
  height: var(--audio-play-button-size, 30px);
  background-color: var(--audio-play-button-bg, white);
  color: var(--audio-play-button-color, black);
  border-radius: var(--audio-play-button-border-radius, 100%);
  min-width: unset;
  min-height: unset;
}

:where(media-community-skin[data-audio] media-play-button svg) {
  border-radius: var(--audio-play-button-border-radius, 100%);
}

:where(media-community-skin[data-audio][data-mobile] media-play-button) {
  margin: 0 10px;
}

:where(media-community-skin[data-audio][data-mobile] div[part='controls-group']:first-child) {
  margin-top: 8px;
  margin-bottom: 16px;
}

:where(media-community-skin[data-audio][data-mobile] div[part='controls-group']:nth-child(3)) {
  margin-top: -12px;
}

:where(media-community-skin[data-audio][data-mobile] div[part='controls-group']:last-child) {
  margin-top: -8px;
  margin-bottom: 16px;
}

:where(
    media-community-skin[data-audio][data-mobile]
      div[part='controls-group']:last-child
      [data-media-button]
  ) {
  width: 36px;
  height: 36px;
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Sliders
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] media-time-slider) {
  width: 100%;
  margin-top: -8px;
  z-index: 98;
}

:where(media-community-skin[data-audio] media-volume-slider) {
  min-width: 72px;
  max-width: 72px;
  margin-left: -0.5px;
  margin-right: 8px;
  transition: transform 0.15s ease;
}

:where(media-community-skin[data-audio] media-time-slider [slot='preview']) {
  bottom: 48px;
}

:where(media-community-skin[data-audio][data-mobile] media-time-slider [slot='preview']) {
  bottom: 42px;
}

:where(media-community-skin[data-audio] media-volume-slider [slot='preview']) {
  bottom: 84px;
}

:where(media-community-skin[data-audio] [part='chapter-title']) {
  color: var(--audio-slider-chapter-title-color, black);
  margin-bottom: 6px;
}

:where(media-community-skin[data-audio][data-mobile] [part='chapter-title']) {
  display: none;
}

:where(media-player[data-waiting], media-player:not([data-can-play]))
  :where(media-community-skin[data-audio] [part~='track-progress']) {
  --stripe-color: var(--audio-buffering-stripe-color, rgb(0 0 0 / 0.25));
  --stripe-size: var(--audio-buffering-stripe-size, 30px);
  width: 100% !important;
  background-image: linear-gradient(
    -45deg,
    var(--stripe-color) 25%,
    transparent 25%,
    transparent 50%,
    var(--stripe-color) 50%,
    var(--stripe-color) 75%,
    transparent 75%,
    transparent
  );
  background-size: var(--stripe-size) var(--stripe-size);
  animation: audio-track-progress var(--audio-buffering-stripe-speed, 2s) linear infinite;
}

@keyframes audio-track-progress {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: var(--stripe-size) var(--stripe-size);
  }
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Menus
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] media-menu:not([data-submenu]) > [data-media-menu-items]) {
  max-height: var(--audio-menu-max-height, 320px);
}

/*
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 * Captions
 * ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
 */

:where(media-community-skin[data-audio] media-captions) {
  --cue-font-size: var(--audio-cue-font-size, 14px);
  display: inline-block;
  position: absolute;
  width: 100%;
  top: unset;
  bottom: 100%;
  text-align: center;
  min-height: var(--audio-captions-min-height, 28px);
  margin-bottom: var(--audio-captions-offset, 6px);
  background-color: var(--audio-captions-bg, transparent);
}

:where(media-community-skin[data-audio] media-captions [part='cue']) {
  color: var(--audio-cue-color, white);
  border: var(--audio-cue-border, 1px solid rgb(255 255 255 / 0.1));
  background-color: var(--audio-cue-bg, black);
}

:where(
    media-player[data-preview] media-community-skin[data-audio]:not([data-mobile]) media-captions
  ) {
  opacity: 0;
}
